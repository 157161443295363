<template>
    <div>
    <Header/>
    <div class="blank20"/>
    <div v-loading="loading" class="course-detail">
        
        <div class="detail">
            <div class="detail-header">
                <i class="safety-icon-home-fill" />
                <span>学习中心</span>
                <!-- <span class="quto">></span>
                <span class="second">{{ courseInfo.categoryName }}</span> -->
                <span class="quto">></span>
                <span class="second">{{ courseInfo.title|formatName }}</span>
            </div>

            <div class="course-header-content">
                <div :style="{height:screenWidth>=1600?'380px':'319px'}" class="cover-img">
                    <img :src="courseInfo|formatCourseImg" />
                    <!-- <div class="opzhezhao" /> -->
                    <!-- <i style="cursor:pointer" class="safety-icon-bofang" @click="goLearning" /> -->
                </div>
                <div :class="screenWidth>=1600?'extend-course-info':''" class="course-info">
                    <div class="course-name">
                        <span ref="courseName" :title="courseInfo.title" class="name">{{ courseInfo.title|formatName }}</span>
                        <div class="shoucang" @click="collect">
                            <i
                                :style="{color:Number(courseInfo.is_collect)===1?'#FD583E':'#bfbfbf'}"
                                class="fa icon-favorites-fill"
                            />&nbsp;&nbsp;收藏<span style="font-size:12px;">&nbsp;&nbsp;{{ courseInfo.collect_num|formartCollect }}</span>
                        </div>
                    </div>
                    <div ref="courseDesc" class="course-desc">
                        {{ courseInfo.intro }}
                    </div>
                    <div class="button-area">
                        <div
                            class="detail-button start-learn"
                            @click="goLearning"
                        >
                            立即学习
                        </div>
                        <div class="learning-persons">
                            <i class="safety-icon-fire" style="vertical-align:middle" />
                            <span style="vertical-align:middle">累计点击量&nbsp;{{ courseInfo.click_num }}</span>
                        </div>
                        <!-- <div class="learning-persons">
                            <i class="safety-icon-user-line" />&nbsp;
                            <span>学习人数&nbsp;{{ courseInfo.onlineLearning }}</span>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="spit-line" />

            <div class="bottom-left">
                <div class="header">
                    <div
                        :class="currentType===1?'select':''"
                        class="type"
                        style="margin-left:0px;"
                        @click="currentType=1"
                    >
                        课程内容
                    </div>
                    <div
                        :class="currentType===2?'select':''"
                        class="type"
                        @click="currentType=2"
                    >
                        课程章节
                    </div>
                </div>
                <Content v-if="currentType===1&&courseInfo.desc" :detail="courseInfo.desc" />
                <Chapter v-if="currentType===2&&courseInfo.chapter_list" :data="{chapterData:courseInfo.chapter_list,courseType:1,courseId:courseInfo.id,courseInfo}" />
            </div>
        </div>
        
    </div>
    <div class="blank20"/>
    <Footer/>
    </div>
</template>
<script>
import Chapter from './components/chapter.vue';
import Content from './components/content.vue';

export default {
    components: {
        Chapter,
        Content,
    },
    filters: {
        formatCourseImg(item) {
            if (item.cover === '') {
                return require('../assets/fengmian.png');
            }
            return item.image;
        },
        formartCollect (val) {
            if(Number(val) >= 100){
                return 99;
            }
            return Number(val);
        },
        // formatLectureImg (val) {
        //     if (val === '') {
        //         // eslint-disable-next-line global-require
        //         return require('../image/header.png');
        //     }
        //     return val;
        // },
        formatName(str) {
            if(str.length > 54){
                return `${str.slice(0, 50)}...`;
            }
            return str;
        },
        formatScore(val){
            return Number(val).toFixed(1);
        },
    },
    data() {
        return {
            courseInfo: {},
            courseList: [],
            lecturerInfo: {},
            // 1课程内容 2课程章节 3课程评价
            currentType: 1,

            // 章节列表
            chapterData: [],
            screenWidth:'100%',
            loading: false,
        };
    },
    computed: {
    },
    watch: {
        currentType() {
            if (this.currentType === 2) {
                //    获取章节信息
            } else if (this.currentType === 3) {
                //    获取评论信息
            }
        },
        screenWidth() {
            // this.screenWidth>=1600
        },
    },
    mounted() {
        const that = this;

        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.scrollWidth;
                that.screenWidth = window.screenWidth;
            })();
        };
        that.screenWidth = document.body.scrollWidth;
        if (this.$route.query.id) {
            this.getCourseInfo(this.$route.query.id);
        } else {
            this.$router.push('course');
        }
    },

    methods: {
        // eslint-disable-next-line consistent-return
        goLearning() {
            if (arguments.length >= 2) {
                // eslint-disable-next-line prefer-rest-params
                this.$router.push(`/learningCourse?id=${this.courseInfo.id}&section=${arguments[1]}`);
            } else {
                window.open(`learningCourse?id=${this.courseInfo.id}`);
            }
        },
        async collect() {
            const params = {
                id:this.$route.query.id,
                is_collect:1 - Number(this.courseInfo.is_collect),
            }
            const res = await this.$http.post('/api/course/userCollectCourse',params);
            if(res){
                this.$set(this.courseInfo,'is_collect',1 - Number(this.courseInfo.is_collect));
                this.$set(this.courseInfo,'collect_num',Number(this.courseInfo.is_collect)===0?(Number(this.courseInfo.collect_num)-1):(Number(this.courseInfo.collect_num)+1));
            }
        },
        async getCourseInfo(id) {
            const res = await this.$http.get('/api/course/getCourseDetail',{id});
            if(res){
                this.courseInfo = res;
            }
        },

        // 文字溢出
        textep(currentref, rowHeight, text) {
            const el = this.$refs[currentref];
            for (let i = 0; i < text.length; i++) {
                el.innerHTML = text.substr(0, i); // 表示在for循环中取出长度递增的文段
                if (el.scrollHeight > rowHeight) {
                    el.style.overflow = 'hidden'; // 将父级元素设置为隐藏
                    el.innerHTML = `${text.substr(0, i - 3)}...`; // 最后三个字
                    break;
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.course-detail {
    overflow: auto;
    // min-width: 1200px;
    @media only screen and (min-width:1366px) {
        min-width:1200px;
    }
    @media only screen and (min-width:1600px) {
        min-width:1420px;
    }
    background-color: #fff;
    height: 100%;
    .detail {
        padding: 0 20px;
        width: 1160px;
        @media only screen and (min-width:0px) {
            width:1160px;
        }
        @media only screen and (min-width:1600px) {
            width:1380px;
        }
        margin: auto;
        background-color: #fff;
        font-size: 0px;
        .detail-header {
            font-size: 14px;
            color: #808080;
            height: 53px;
            line-height: 53px;
            i {
                color: #4c85ff;
                display: inline-block;
                margin-right: 7px;
            }
            .quto {
                color: #808080;
                display: inline-block;
                padding: 0 5px;
            }
            .second {
                color: #454545;
            }
        }

        .course-header-content {
            .cover-img {
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 50%;

                img {
                    width: 100%;
                    height:100%;
                    border-radius: 3px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background-color: #000;
                }
                .opzhezhao {
                    width: 100%;
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    background-color: #000;
                    opacity: 0.5;
                    border-radius: 3px;
                    position: absolute;
                }
                i {
                    position: absolute;
                    font-size: 80px;
                    color: #fff;
                    top: calc(50% - 40px);
                    left: calc(50% - 40px);
                }
            }

            .course-info {
                width: calc(50% - 60px);
                display: inline-block;
                vertical-align: top;
                margin-left: 55px;
                .course-name {
                    margin-top: 15px;
                    position: relative;
                    font-size: 22px;
                    font-weight: 400;
                    color: #454545;
                    .name {
                        display: inline-block;
                        height: 50px;
                        width: 400px;
                    }
                    .share {
                        cursor: pointer;
                        text-align: center;
                        position: absolute;
                        right: 99px;
                        top: 0px;
                        width: 68px;
                        height: 28px;
                        line-height: 28px;
                        background: rgba(252, 252, 252, 1);
                        border: 1px solid rgba(242, 242, 242, 1);
                        border-radius: 2px;
                        font-size: 14px;
                        font-family: MicrosoftYaHei;
                        font-weight: 400;
                        color: #808080;
                    }
                    .shoucang {
                        cursor: pointer;
                        text-align: center;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        width: 86px;
                        height: 28px;
                        line-height: 28px;
                        background: rgba(252, 252, 252, 1);
                        border: 1px solid rgba(242, 242, 242, 1);
                        border-radius: 2px;
                        font-size: 14px;
                        font-family: MicrosoftYaHei;
                        font-weight: 400;
                        color: #808080;
                        i{
                            font-size: 16px;
                        }
                        span{
                            display: contents;
                        }
                    }
                }

                .rate {
                    position: relative;
                    margin-top: 0px;
                    font-size: 18px;
                    ::v-deep.el-rate {
                        .el-rate__item .el-rate__icon {
                            font-size: 30px;
                        }

                        .el-rate__text {
                            font-size: 18px;
                        }
                    }
                }

                .course-desc {
                    margin-top: 30px;
                    font-size: 14px;
                    line-height: 25px;
                    height:141px;
                    color: #808080;
                    word-break: break-all;
                }

                .button-area {
                    position: relative;
                    margin-top: 22px;
                    .detail-button {
                        width: 118px;
                        height: 38px;
                        line-height: 38px;
                        border: 1px solid #4c85ff;
                        border-radius: 20px;
                        text-align: center;
                        font-size: 16px;
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .start-learn {
                        color: #fff;
                        font-weight: bold;
                        background-color: #4c85ff;
                    }
                    .half-opacity{
                        opacity: 0.5;
                    }
                    .start-test {
                        color: #4c85ff;
                        font-weight: 400;
                        margin-left: 20px;
                        background-color: #fff;
                    }

                    .learning-persons {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 12px;
                        color: #a6a6a6;
                        margin-left: 26px;
                        top: 13px;
                        span{
                            display: inline-block;
                        }
                    }
                }
            }

            .extend-course-info{
                width: calc(50% - 60px);
                margin-left: 55px
            }
        }

        .spit-line {
            background-color: #f7f9fa;
            height: 5px;
            width: calc(100% + 40px);
            margin-top: 25px;
            margin-left: -20px;
        }

        .bottom-left {
            width: calc(100% - 320px);
            display: inline-block;
            vertical-align: top;
            .header {
                width: 100%;
                height: 56px;
                border-bottom: 2px solid #f7f9fa;
                background-color: #fff;
                .type {
                    width: 90px;
                    height: 52px;
                    line-height: 54px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #808080;
                    display: inline-block;
                    text-align: center;
                    margin-left: 50px;
                    cursor: pointer;
                }
                .select {
                    color: #4c85ff;
                    position: relative;
                }
                .select::after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 90px;
                    bottom: -3px;
                    left: 0px;
                    background-color: #4c85ff;
                }
            }
        }

        .bottom-right {
            width: 290px;
            display: inline-block;
            vertical-align: top;
            margin-left: 30px;
            .header {
                width: 100%;
                height: 56px;
                border-bottom: 2px solid #f7f9fa;
                background-color: #fff;
                .type {
                    width: 90px;
                    height: 52px;
                    line-height: 54px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #808080;
                    display: inline-block;
                    text-align: center;
                }
            }

            .lec-img {
                width: 124px;
                display: block;
                height: 124px;
                background: rgba(243, 249, 255, 1);
                border-radius: 50%;
                margin: auto;
                margin-top: 37px;
                cursor: pointer;
            }
            .title {
                height: 30px;
                font-size: 14px;
                font-weight: bold;
                color: rgba(51, 51, 51, 1);
                line-height: 30px;
                text-align: center;
            }
            .subtitle {
                height: 11px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(128, 128, 128, 1);
                line-height: 16px;
                text-align: center;
            }
            .course-list {
                margin-top: 40px;
                .course-li {
                    width: 140px;
                    margin-bottom: 20px;
                    margin-left: 10px;
                    display: inline-block;
                    .course-img {
                        width: 140px;
                        height: 80px;
                    }
                    span {
                        margin-top: 4px;
                        display: inline-block;
                        width: 140px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        color: #808080;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
</style>
