<template>
    <div class="chapter">
        <div v-for="(item,index) in data.chapterData" :key="index" style="width:100%">
            <div :title="item.title" style="padding-left:10px;" class="commonC jie">
                <i class="fa icon-course-dration" />&nbsp;{{ item.title }}
                <span class="jie-learning-time">{{ item|learningTime }}</span>
                <span class="start-learning" @click="goLearning(item)">开始学习</span>
            </div>
        </div>
    </div>
</template>
<script>
// import {
//     NAMESPACE,
//     AddPcOnlineLearning,
// } from '../vuex';

export default {
    filters:{
        learningTime(val){
            // type = Number(type);
            // if(val.finished === 1){
            //     return '已完成';
            // }
            // if (Number(val.status) !== 2 && Number(val.status) !== 3){
            //     return '转码中';
            // }
            // if (Number(val.status) === 3){
            //     return '转码失败';
            // }

            let hour;
            let minute;
            let second;
            const allSeconds = Number(val.course_duration);
            hour = Math.floor(allSeconds / 3600);
            // eslint-disable-next-line no-mixed-operators
            minute = Math.floor(allSeconds % 3600 / 60);
            second = allSeconds % 60;
            minute < 10 && (minute = `0${minute}`);
            hour < 10 && hour > 0 && (hour = `0${hour}:`);
            hour === 0 && (hour = '');
            hour >= 10 && (hour += ':');
            second === 0 && (second = '00');
            second < 10 && second > 0 && (second = `0${second}`);
            return `${hour + minute}:${second}`;
        },
    },
    props:{
        data:{
            type:Object,
            required:true,
        },
    },
    data() {
        return {
            chapterData:[],
            courseType:'',
        };
    },
    watch: {
        // chapterData:{
        //     hander:function(){
        //         console.log(this.chapterData);
        //     },
        //     deep:true,
        // }
    },
    mounted() {
        console.log('data',this.data);
        const that = this;
        this.chapterData = this.data.chapterData;
        this.courseType = this.data.courseType;
        this.chapterData.forEach((item, index) => {
            if(item.belong === 1){
                that.$set(that.chapterData[index], 'isShow', true);
            }
        });
    },
    methods: {
        showChildren(index){
            this.$set(this.chapterData[index], 'isShow', !this.chapterData[index].isShow);
        },
        goLearning(item){
            // this.$store.dispatch(`${NAMESPACE}/${AddPcOnlineLearning}`, {
            //     courseId: this.data.courseId,
            // });
            window.open(`learningCourse?id=${this.data.courseId}&itemId=${item.id}`);
        },
    },
};
</script>
<style lang="scss" scoped>
.chapter{
    width:100%;
    padding-top: 10px;
    .commonC{
        height:60px;position: relative;line-height:60px;
        padding-right: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .zhang{
        color:#808080;font-size: 14px;
        box-sizing: border-box;
        padding-left: 10px;
        i{
            position: absolute;
            right: 10px;
            cursor: pointer;
        }
    }
    .jie{
        color:#808080;
        padding-left: 20px;
        font-size: 14px;
        &:hover{
            background-color: #F3F9FF;
            color:#4C85FF;
            .start-learning{
                display: block;
                cursor: pointer;
            }
            .jie-learning-time{
                display: none;
            }
        }
        i{
            color:#4C85FF;font-size: 16px;
        }

        .jie-learning-time{
            position: absolute;
            right:10px;
        }
        .start-learning{
            width:90px;
            height:30px;
            background:rgba(76,133,255,1);
            border-radius:15px;
            font-size: 14px;
            font-weight: bold;
            color:#fff;
            text-align: center;
            line-height: 30px;
            position: absolute;
            top:15px;
            right:10px;
            display: none;
        }
    }
}
</style>
