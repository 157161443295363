<template>
    <div ref="content" class="ddetail" />
</template>
<script>
export default {
    props:{
        detail:{
            type:String,
            required:true,
        },
    },
    mounted() {
        this.$refs.content.innerHTML = this.detail;
    },
    // data() {
    //     // return {
    //     //     data:'',
    //     // };
    // },
};
</script>
<style lang="scss" scoped>
.ddetail{
    width: 100%;
    font-size: 12px;
    padding-top: 20px;
    word-break: break-all;
}
</style>
